import React from 'react';
import Layout from '../components/layout';
import Chicken from '../../static/chicken.svg';
import Media from 'react-media';
import { Hero, Container } from 'react-bulma-components';

class Firstpage extends React.Component{
  render(){
    return(
      <div>
        <Container fluid className="thatHolder">
            <div className="circle"></div>
            <blockquote className="textSide">We provide the finest pharmaceutical poultry products</blockquote>
            <div className="chicken"><img src={Chicken} /></div>
            </Container>
      </div>
    );
  }
}

export default () => (
    <Layout scroll title="Lebanon Poultry">

            <Media query="(max-width:900px)">
                    {matches =>
                    matches ? (
                      <div>
                        <div className="testingHolder"></div>
                      <Container fluid className="mobileThatHolder">
                      <div className="mobileChicken"><img src={Chicken} /></div>
                            <div className="mobileCircle"></div>
                    <blockquote className="mobileTextSide">We provide the finest pharmaceutical poultry products</blockquote>
                    
                      </Container>
                      </div>
            ) : (
              <Firstpage></Firstpage>
            )
          }
        </Media>
        
            
            
            
            
        
    
    </Layout>
)
